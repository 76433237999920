@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --body-bg: #f0f3f4;
  --text-primary: #2c3c46;
  --text-secondary: #777777;
  --text-accent: #9f523c;
}

@font-face {
  font-family: 'Commissioner';
  src: local('Commissioner'),
    url(./assets/fonts/Commissioner-VariableFont_wght.ttf) format('truetype');
}

html,
body {
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Commissioner', sans-serif;
  color: var(--text-primary);
}

input,
select {
  outline: 0;
}

body {
  background-color: var(--body-bg);
}

#root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}
